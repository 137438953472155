import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// export default new Vuetify({
//   theme: { dark: true },
// });
export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: colors.purple,
        // background: colors.indigo.base, // If not using lighten/darken, use base to return hex
      },
      dark: {
        background: "#000729",
      },
    },
  },
});
