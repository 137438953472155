<template>
  <v-footer absolute color="transparent" padless>
    <v-card flat tile color="transparent" width="100%" class="text-center">
      <v-card-text>
        {{ new Date().getFullYear() }} — Landstrider UG (haftungsbeschränkt)
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
