<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <img
          id="main-logo"
          alt="Landstrider Logo"
          src="../assets/testlogo2.svg"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
#main-logo {
  width: 720px;
  max-width: 80vw;
}
</style>
