<template>
  <v-app-bar app color="transparent" flat id="navbar">
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="../assets/minitestlogo1.svg"
        transition="scale-transition"
        width="40"
      />
    </div>

    <v-spacer></v-spacer>

    <!-- DARK MODE SWITCH -->
    <div>
      <v-tooltip v-if="!$vuetify.theme.dark" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="darkMode">
            <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
          </v-btn>
        </template>
        <span>Dark</span>
      </v-tooltip>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="darkMode">
            <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
          </v-btn>
        </template>
        <span>Light</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",

  data: () => ({
    //
  }),

  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style scoped>
#navbar {
  backdrop-filter: blur(25px);
}
</style>
