import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"app":"","color":"transparent","flat":"","id":"navbar"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":require("../assets/minitestlogo1.svg"),"transition":"scale-transition","width":"40"}})],1),_c(VSpacer),_c('div',[(!_vm.$vuetify.theme.dark)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""},on:{"click":_vm.darkMode}},on),[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-moon-waxing-crescent")])],1)]}}],null,false,3478940519)},[_c('span',[_vm._v("Dark")])]):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""},on:{"click":_vm.darkMode}},on),[_c(VIcon,{attrs:{"color":"yellow"}},[_vm._v("mdi-white-balance-sunny")])],1)]}}])},[_c('span',[_vm._v("Light")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }